@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.logos {
    overflow: hidden;
    padding: 35px 0;
    background: white;
    white-space: nowrap;
    position: relative;
}

.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 600px; /* Adjust this value to control the gradient width */
    height: 100%;
    content: "";
    z-index: 2;
}
 


.logos:hover .logos-slide {
    animation-play-state: paused;
}

.logos-slide {
    display: inline-block;
    animation: 30s slide infinite linear;
}

.logos-slide img {
    height: 100px;
    margin: 0 40px;
}
@media screen and (max-width : 480px){
.logos:before,
.logos:after {
    position: absolute;
    top: 0;
    width: 50px; /* Adjust this value to control the gradient width */
    height: 100%;
    content: "";
    z-index: 2;
}

.logos {
    overflow: hidden;
    padding:0px 0;
    background: white;
    white-space: nowrap;
    position: relative;
}

}
