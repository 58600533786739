.tech-card {
  position: relative;
  height: 350px;
}
.card-text {
  position: absolute;
  bottom: 1%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}

.card-flip-text {
  position: absolute;
  bottom: -15%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}

.tech-card .flip-card .card {
  background-color: #fafafa;
}
.inside-card .icon {
  /* background-color: var(--primary-background); */
  width: 15%;
  /* border-radius: 50%; */
  padding: 0.2rem;
  margin-left: 0.5rem;
}
.inside-card .icon img {
  margin: -10px 0 0 -18px;
}
.tech-card .flipped-card .card {
  height: 313px;
}

/* Hover effect  */

.tech-card:hover .unflipped {
  display: none !important;
}
.tech-card:hover .flipped-card {
  display: block !important;
}

.unflipped .card-txt {
  position: absolute;
  bottom: 3%;
  left: 6%;
  right: 6%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}

/* Flip animation  */

.flip {
  backface-visibility: visible !important;
  animation: flip 800ms ease;
}

@keyframes flip {
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0);
  }
}

/* Mediaquery  */
@media screen and (max-width: 1100px) {
  img.card-img-top {
    height: 230px;
  }
  .tech .sec-main-img {
    display: none;
  }
  .tech-card .flipped-card .card {
    height: auto;
  }
  .card-txt {
    bottom: 3.5%;
    left: 4.5%;
    right: 4.5%;
  }
}


@media (min-width:2200px) and (max-width:2250px) {
  .fontforimac{
    font-size: 18px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .card-text {
    position: absolute;
    bottom: 3%;
    top: 84%;
    left: 7%;
    right: 7%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    padding: 0.6rem 0;
  }

  .card-text {
    position: absolute;
    bottom: 3%;
    top: 84%;
    left: 7%;
    right: 7%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    padding: 0.6rem 0;
  }

  .card-flip-txt h6 {
    font-size: 11px;
  }

  .card-flip-text {
    position: absolute;
    bottom: -15%;
    top: 292px;
    left: 8%;
    right: 3%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    padding: 0.6rem 0;
  }
  .tech-card .flipped-card .card {
    height: 328px;
  }

  .card-text h6 {
    font-size: 11px;
  }

  .card-flip-text h6 {
    font-size: 11px;
  }
  .tech-card .unflipped img {
    height: 310px;
  }
}

/* Mobile  */
@media screen and (max-width: 480px) {
  img.card-img-top {
    width: 100%;
      height: 225px;
      /* maintains the aspect ratio */
      object-fit: cover;
  }
  .card-txt h4 {
    font-size: 17px;
  }
  /* .card-body {
    padding: 0.5rem !important;
  } */
  .inside-card .icon {
    width: 25%;
    margin-left: 1rem;
  }
  .inside-card h5 {
    font-size: 18px;
  }
  .inside-card p {
    font-size: 10px;
  }
  .tech-card .flipped-card .card {
    /* height: auto; */
    height: 230px !important;
  }

  .title-heading {
    font-size:15px;
  }

  .tech-card {
    position: relative;
    height: 215px;
  }
  .card-text {
    position: absolute;
    bottom: -2%;
    top: 100%;
    left: 8%;
    right: 3%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    /* padding: 0.6rem 0; */
  }

  .card-flip-text {
    position: absolute;
    bottom: -15%;
    left: 8%;
    right: 3%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    /* padding: 0.6rem 0; */
  }
  .tech-card .flipped-card .card {
    height: 230px;
  }
}

@media screen and (max-width: 300px) {
  img.card-img-top {
    height: 140px;
  }
  .inside-card .icon {
    width: 40%;
  }
}

.cardtext {
  color: #717171;
}

/* @media (min-width: 2519px) and (max-width: 4486px) {
    .font-custom {
    font-size: 17px !important;
  }
   h6{
    font-size: 20px !important;
  }
   h5{
    font-size: 20px !important;
  }
} */
/* for imac */
@media screen and (min-width: 2240px) and (max-width: 2250px) {
  .flipped-card .title-heading {
    font-size: 22px !important;
  }
  .unflipped .title-heading {
    font-size: 22px !important;
  }

  .x .cardtext {
    font-size: 20px !important;
  }
  .inside-card .font-custom {
    font-size: 20px !important;
  }

  .card-text {
    position: absolute;
    bottom: -2%;
    left: 8%;
    right: 3%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    padding: 0.6rem 0;
  }

  .card-flip-text {
    position: absolute;
    bottom: -15%;
    left: 8%;
    right: 3%;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    padding: 0.6rem 0;
  }

  .tech-card .flip-card .card {
    background-color: #fafafa;
  }
  .inside-card .icon {
    /* background-color: var(--primary-background); */
    width: 15%;
    /* border-radius: 50%; */
    padding: 0.2rem;
    margin-left: 0.5rem;
  }
  .inside-card .icon img {
    margin: -10px 0 0 -18px;
  }
  .tech-card {
    position: relative;
    height: 350px;
    width: 390px;
    z-index: 10;
  }
  /* .unflipped .flip .tech-card {
    position: relative;
    height: 370px;
    width: 390px;
    z-index: 10;
  } */
  .tech-card .flipped-card .card {
    height: 330px;
    z-index: 10;
    width: 390px;
  }
}

@media screen  and (min-width:1250px) and (max-width:1290px) {
  .card-text {
  position: absolute;
  bottom: 14%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}

}
@media screen and (min-width:1350px) and (max-width:1370px) {
  .card-text {
  position: absolute;
  bottom: 11%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}
}
@media screen and (min-width:1550px) and (max-width:1600px) {
  .card-text {
  position: absolute;
  bottom: -8%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}
}

@media screen and (min-width:1650px) and (max-width:1690px) {
  .card-text {
  position: absolute;
  bottom: -15%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}
}
@media screen and (min-width:1900px) and (max-width:1930px) {
  .card-text {
  position: absolute;
  bottom: -30%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}
}

@media screen and (min-width:1350px) and (max-width:1400px) {
  .card-text {
  position: absolute;
  bottom: 7%;
  left: 8%;
  right: 3%;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  padding: 0.6rem 0;
}
}