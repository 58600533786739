.brand-logo.navbar-brand {
  width: 10%;
}

#Contact {
  border: 1px solid#288ED7;
  width: 150px;
  text-align: center;
  border-radius: 8px;  
  color: #288ED7;
}

#Contact:hover {
  width: 150px;
  text-align: center;
  border-radius: 8px;
  background-color: #288ED7;
  color: white !important;
}

.non-active {
  color: #288ED7 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color:#288ED7!important;
}

.header {
  box-shadow: 0 1.2px 8px rgba(0, 0, 0, 0.1);
}

.header button.navbar-toggler.collapsed {
  border: none;
}



.navbar {
  --bs-navbar-toggler-focus-width: 0 !important;
}
div#offcanvasNavbar-expand-lg {
  width: 60%;
}

.offcanvas.offcanvas-end.offcanvas-toggling {
  width: 60%;
}

/* Mediaquery */
@media screen and (max-width: 1100px) {
  .brand-logo.navbar-brand {
    width: 15%;
  }

  #logo {
    width: 30%;
    height: 30%;
  }
/* 
  #datart {
    width: 70%;
  } */
}

@media screen and (max-width: 820px) {
  .brand-logo.navbar-brand {
    width: 20%;
  }
}



@media screen and (max-width: 480px) {
  .brand-logo.navbar-brand {
    width: 45%;
  }
  #Contact:hover {
    width: 109px !important;
    text-align: center;
    border-radius: 8px;
    background-color: #288ED7;
    color: white !important;
  }
  #Contact {
    border: 1px solid #288ED7;
    width: 110px;
    text-align: center;
    border-radius: 8px;
    color:#288ED7
  } 
  #datart{
    width: 120px !important;
    height: 40px !important;
  }
  

}

@media screen and (max-width: 300px) {
  .brand-logo.navbar-brand {
    width: 40%;
  }

  #Contact:hover {
    width: 110px !important;
    text-align: center;
    border-radius: 8px;
    background-color:#288ED7;
    color: white !important;
  }

  #Contact {
    border: 1px solid #288ED7;
    width: 110px;
    text-align: center;
    border-radius: 8px;
    color: #412201;
  }
}
