.bgfordivcase1{
   background-color: #EEF2F4;
}
.bgfordivcase2{
    background-color:#EEF2F4;
}
.bgfordivcase3 {
    background-color: #EEF2F4;
}


@media screen  and (min-width:1250px) and (max-width:1290px) {
  

}