/* Banner  */
.banner {
  position: relative;
}
.banner-img {
  height: 600px;
}
.invalid-feedbackss{
  color: #DC3545;
  font-size: small;
}


.hrStyle{
  position: absolute;
  border: none;
  width: 85%;
  top: 15%;
  left: 2%;
  height: 0.25rem;
  background-image: linear-gradient(to right, var(--primary-color) 70%, rgba(255, 255, 255, 0) 0%);
  background-size: 30px;
}
.pad-img {
  box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
  border-radius: 5px;
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;
}

.bg{
  background-color: #d9d9d9;
}
.inovation-driven {
  background-image: url(../images/homeImages/innovation-driven-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.close-button-popup {
  background-color: rgba(0, 0, 0, 0.317);
}
.cursor-pointer {
  cursor: pointer;
}

/* .background-special-offer {
  background-image: url(../images/homeImages/background-special-offer.png);
  background-size: cover;
  background-repeat: no-repeat;
} */

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* color: var(--bs-modal-color); */
  pointer-events: auto;
  /* background-color: var(--bs-modal-bg); */
  /* background-clip: padding-box; */
  /* border: var(--bs-modal-border-width) solid var(--bs-modal-border-color); */
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.modal-header-bg {
  background-image: url(../images/homeImages/Modalbody.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-box {
  position: absolute;
  top: 24%;
  left: 9%;
  right: 45%;
}
.choose-us-bg{
  background-image: url(../images/homeImages/choose-us-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 5%;
}
.inner-box #h1 {
  font-size: 2.5rem !important;
  padding: 0.7rem 0;
}

/* .bannerbackgroundlanding{
  background-image: url('../images/bgbanner.png');
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 630px;
} */
.fontforh1{
  font-size: 60px;
}
.buttontextcolor{
  color: #BB6722;;
}
/* .backgroundoffer{
  background-image: url("../images/backgroundoffer.png");
  background-size: cover;
    background-position: center;
    
} */
.fontforoff{
  font-size: 60px;
}
.textcolor{
  color: #BC3B1E;;
}


/* Technologies  */
.tech .tech-heading .secondary-light-color {
  padding: 0 21rem;
}
.tech .sec-main-img {
  width: 107%;
  margin-left: -40px;
  margin-top: -20px;
}

/* Solutions */
.solution-heading p {
  padding: 0 19rem;
}

/* .special-offer {
  background-image: url(../images/homeImages/specialofferbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color:  #D1F1FF;
  width: 100%;

  height: auto;
} */
.text-special-offer {
  text-shadow: 2px 1px 1px white;
}

/* Stats  */
.big-font {
  font-size: 3rem;
}
.border-style-left {
  border-left: 1px solid #eaecf0;
}

/* Ecosystem  */
/* .bg-ecosystem {
  background-image: url("../images/bg-ecosystem.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */
.eco-box {
  padding: 5rem 0;
}
.box-style {
  height: 12rem;
  background-color: white;
  box-shadow: 0 1.2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.chose-txt {
  top: 40%;
}

/* After choose  */
.after-choose .inside-data {
  position: absolute;
  top: 20%;
  left: 10%;
}
.approches,
.approches .secondary-light-color {
  padding-right: 4.3rem;
}
.approches h3 {
  width: 55px;
  padding: 0.6rem 0.8rem;
  background-color: #f2f2f2;
  border-radius: 50%;
}

/* Success stories  */
/* .back-success {
  background-image: url("../images/success-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 3.5rem;
} */


.contact-txt{
color: #D78028;
text-decoration: underline ;
cursor: pointer;
}

/* .bgcontactinput{
   background-color: #131C29 !important;


} */
/* .bgcontactinput:focus{
  background-color:  #131C29 !important;
  color: white !important;
 
} */

.backgroundforcontact{
  background-image: url("../images/contactsectionbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 610px;
}
/* Mediaquery  */

.bgforreadytolaunch{
  background-image: url("../images/bgfoready.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 250px;
  
}
@media (max-width: 346px) {
  #img {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  #img {
    display: none;
  }
}

@media screen and (max-width: 1100px) {
  /* Banner */
  .banner-img {
    height: 400px;
  }
  .banner-box {
    right: 14%;
    top: 18%;
    
  }
  .inner-box #h1 {
    font-size: 2rem !important;
  }

  /* Technologies */
  .tech .tech-heading .secondary-light-color {
    padding: 0;
  }

  /* Solutions  */
  .solution-heading p {
    padding: 0;
  }

  /* Stats  */
  .stats img {
    width: 15%;
  }


  /* Choose us */
  .choose-us .position-absolute.top-50 {
    top: 28% !important;
    padding: 0 1rem;
  }
  .box-style p.secondary-light-color {
    font-size: 13px;
    padding-bottom: 1.5rem;
  }
  .box-style {
    height: auto;
  }
  .eco-box {
    padding: 3rem 0;
  }
  .chose-txt {
    top: 28%;
  }

  /* After choose  */
  .approches,
  .approches .secondary-light-color {
    padding-right: 0.3rem;
  }
  .after-choose .inside-data {
    position: initial;
    padding: 3rem 0 3rem 4rem;
  }
  
  .after-choose img {
    display: none !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
  .tech .tech-heading .secondary-light-color,
  .solution-heading p {
    padding: 0 10rem;
  }
 
  .approches,
  .approches .secondary-light-color {
    padding-right: 1rem;
  }
}

@media screen and (min-width: 700px) and (max-width: 830px) {
 
  .approches {
    margin-bottom: 2rem !important;
  }
  .chose-txt {
    top: 34%;
  }
}
@media screen and (min-width: 810px) and (max-width: 830px) {
  .air-styl {
    display: none;
  }
}






/* for mob query */
@media screen and (max-width: 480px) { 
.bg-primaryfortext{
  font-size: 12px;
} 
  .inner-box #h1 {
    font-size: 1.8rem !important;
  }
  .banner-box .inner-box h1 {
    font-size: 18px;
  }
  .banner-box1 {
    right: 1%;
    left: 7%;
    top: 15%;
  }

  .inner-box p.text-white {
    margin-bottom: 0;
    font-size: 16px;
  }

  /* Stats  */
  .stats img {
    width: 20%;
  }

  /* Choose us  */
  .box-style p.secondary-light-color {
    font-size: 16px;
  }
  .chose-txt {
    top: 27%;
  }

  .after-choose .inside-data {
    position: initial;
    padding: 3rem 0 1rem 2rem;
  }
  .approches {
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  
    .saleformob{
      font-size: 24px !important;
    }
    .saleoffformob{
      font-size: 60px !important;
    }
    .onselectedmob{
      font-size: 14px !important;
    }
    .backgroundforcontactmob{
      background-image: url();
    }
    .bgforreadymob{
      background-image: url('../images/bgreadymob.png');
      background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
      height: 375px;
    }
    /* .bannermobbackground{
      background-image: url('../images/mobilebannerbg.png');
       background-size: cover;
     
        height: 700px;
    } */
    .bannerboxformob {
   position: absolute;
   top: 17%;
   left: 7%;
   /* right: 45%; */
 }
 
/* .staformob{
  position: absolute;
  top: 39%;
} */
.fontformobp{
  font-size: 14px !important;
}
/* .clientimgdiv{
  position: absolute;
   top: 56%;
} */
.btnbannermob{
  background-color: #288ED7 !important;
  color: white !important;
}
h2{
  font-size: 30px !important;
}
p{
  font-size: 15px !important;
}
.fliptext{
  font-size: 12px !important;
}
/* .backgroundforcontactmob{
  background-image: url('../images/contactmobbg.png');
    background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
        height: 1150px;
} */
 .fontsizestats{
  font-size: 40px !important;
 }

}

@media screen and (min-width:400px) and (max-width:415px) {
  .backgroundforcontactmob{
  background-image: url('../images/contactmobbg.png');
    background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
        height: 1080px;
}
.bannermobbackground{
      background-image: url('../images/mobilebannerbg.png');
       background-size: cover;
      /* background-position: center; */
        /* background-repeat: no-repeat; */
        height: 600px;
    }

     .staformob {
        position: absolute;
        top: 34%;
    }
    .clientimgdiv{
  position: absolute;
   top: 40%;
}


 .textformobh1{
  font-size: 29px !important;
 }
 .textformobh4{
  font-size: 15px !important;
 }

}

@media screen and (min-width:380px) and (max-width:385px) {
  .backgroundforcontactmob{
  background-image: url('../images/contactmobbg.png');
    background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
        height: 1040px;
}
.bannermobbackground{
      background-image: url('../images/mobilebannerbg.png');
       background-size: cover;
      /* background-position: center; */
        /* background-repeat: no-repeat; */
        height: 628px;
    }
     .staformob {
        position: absolute;
        top: 37%;
    }
     .clientimgdiv{
  position: absolute;
   top: 50%;
}
}

@media screen and (min-width:350px) and (max-width:360px) {
  .backgroundforcontactmob{
  background-image: url('../images/contactmobbg.png');
    background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
        height: 1015px;
}
.textformobh1{
  font-size: 26px !important;
 }
 .textformobh4{
  font-size: 16px !important;
 }
 .bannermobbackground{
      background-image: url('../images/mobilebannerbg.png');
       background-size: cover;
     
        height: 542px;
    }
    .staformob {
        position: absolute;
        top: 39%;
    }
     .clientimgdiv{
  position: absolute;
   top: 41%;
}
}

@media screen  and (min-width:420px) and (max-width:430px){
  .backgroundforcontactmob{
  background-image: url('../images/contactmobbg.png');
    background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
        height: 1095px;
}
.bannermobbackground{
      background-image: url('../images/mobilebannerbg.png');
       background-size: cover;
      /* background-position: center; */
        /* background-repeat: no-repeat; */
        height: 610px;
    }
.textformobh1{
  font-size: 29px !important;
 }
 .textformobh4{
  font-size: 15px !important;
 }
 .staformob {
        position: absolute;
        top: 35%;
    }
     .clientimgdiv{
  position: absolute;
   top: 40%;
}

}

@media screen  and (min-width:390px) and  (max-width:395px){
  .backgroundforcontactmob{
  background-image: url('../images/contactmobbg.png');
    background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
        height: 1047px;
}
.bannermobbackground{
      background-image: url('../images/mobilebannerbg.png');
       background-size: cover;
      /* background-position: center; */
        /* background-repeat: no-repeat; */
        height: 580px;
    }
     .staformob {
        position: absolute;
        top: 37%;
    }
    .textformobh1{
  font-size: 28px !important;
 }
 .textformobh4{
  font-size: 14px !important;
 }
     .clientimgdiv{
  position: absolute;
   top: 40%;
}

}





@media screen and (min-width:370px) and (max-width:375px) {
   .banner-box1 {
   position: absolute !important;
   top: 15% !important;
   left: 5% !important;
   right: 45% !important;
 }
 .textformobh1{
  font-size: 28px !important;
 }
 .textformobh4{
  font-size: 14px !important;
 }
 .backgroundforcontactmob{
  background-image: url('../images/contactmobbg.png');
    background-size: cover;
      background-position: center;
        background-repeat: no-repeat;
        height: 1047px;
}
 .bannermobbackground{
      background-image: url('../images/mobilebannerbg.png');
       background-size: cover;
      /* background-position: center; */
        /* background-repeat: no-repeat; */
        height: 550px;
    }
        .staformob {
        position: absolute;
        top: 38%;
    }
     .clientimgdiv{
  position: absolute;
   top: 44%;
}


}



:root {
  --PhoneInput-color--focus: #2167b1;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
  /* / This is done to stretch the contents of this component. / */
  display: flex;
  align-items: center;
  outline: #2167b1 !important;
}
.PhoneInput .PhoneInputInput:focus-visible {
  outline: none !important;
  
  border: 1px solid #2167b1 !important;
 
}
.PhoneInput--focus:focus {
 
  border-color: #2167b1 !important;
  box-shadow: 0 0 5px #2167b1 !important; 
}
.PhoneInputInput {
  
  flex: 1;
 
  min-width: 0;
  
  border-width: 1px;
  border-top: #000;
  border-left: #000;
  border-right: #000;

  border-bottom-color: gray;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
 
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
 
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  
  display: block;
 
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}



.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.gradient-text {
  background: linear-gradient(0deg, #7E481B, #D27E3D);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.paddingforbtn{
  padding: 0.7rem;
}
.availoffer{
  border: 1px solid #BC3B1E;
  border-radius: 8px;
}
.inputp:focus{
  outline: none !important; 
  box-shadow: none !important;
}
.phoneinput:focus{
outline: none !important;
  box-shadow: none !important;
  border: none;
}
@media (min-width:2200px) and (max-width:2240px) {
  /* .bannerbackgroundlanding{
    background-image: url('../images/bgbanner.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 800px;
  } */
}

/* .bgcontact{
  background-image: url("../images/contactbg.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */
.inputp:focus{
  outline: none;
  box-shadow: none;
}

.forgetinbut{
  background-color:  #288ED7 !important;
  color: white !important;
}

.forgetinbut:hover{
   background-color:#15639b !important;
   color :white !important;
}

/* new css */

.bannerrole{
  position: relative;
 }
 .banner-box1 {
   position: absolute;
   top: 8%;
   left: 5%;
   right: 28%;
 }

 .bannerbackground{
  background-image: url("../images/bannersectionbg.png");
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 700px;

 }
 .bannerbuton{
  background-color: #013941;
  color: white;
 }
 .clientimgdiv{
  position: absolute;
  left: 4%; 
 }

.typewriter {
  color: #fff;
  /* font-family: monospace; */
  overflow: hidden; 
  white-space: nowrap; 
  /* display: inline-block;  */
  letter-spacing: 0.08rem; 
  animation: blink-caret 0.75s step-end infinite;
}

.heading-effect span {
  display: inline-block;
  overflow: hidden;
  /* border-right: 2px solid orange;  */
  animation: typing-heading 3s steps(30, end) forwards, blink-caret 0.75s step-end infinite;
  color: #151313;
  font-size: 1.5rem;
}

.subheading-effect span {
  display: inline-block;
  overflow: hidden;
  /* border-right: 2px solid orange;  */
  animation: typing-subheading 4.5s steps(40, end) forwards, blink-caret 0.75s step-end infinite;
  font-size: 2.5rem;
  color: #151515;
  /* margin-top: 10px; */
}

@keyframes typing-heading {
  from {
    width: 0;
  }
  to {
    width: 100%; /* Adjust width based on text */
  }
}

@keyframes typing-subheading {
  from {
    width: 0;
  }
  to {
    width: 100%; /* Adjust width based on text */
  }
}

@keyframes blink-caret {
  50% {
    border-color: transparent;
  }
}


/* .clientimgdiv {
  text-align: center;
} */

.outer-bg {
  background: linear-gradient(45deg, #EFEDB9, #9CD4F4); 
  padding: 10px; 
  border-radius:35px 35px 43px 44px ; 
  display: inline-block;
  position: relative;
  animation: blink-outer 2s infinite; /* Blink animation for outer background */
  
  -webkit-clip-path: polygon(
    0% 5%, 5% 0%, 10% 5%, 15% 0%, 
    20% 5%, 25% 0%, 30% 5%, 35% 0%, 
    40% 5%, 45% 0%, 50% 5%, 55% 0%, 
    60% 5%, 65% 0%, 70% 5%, 75% 0%, 
    80% 5%, 85% 0%, 90% 5%, 95% 0%, 
    100% 5%, 100% 95%, 95% 90%, 90% 95%, 
    85% 90%, 80% 95%, 75% 90%, 70% 95%, 
    65% 90%, 60% 95%, 55% 90%, 50% 95%, 
    45% 90%, 40% 95%, 35% 90%, 30% 95%, 
    25% 90%, 20% 95%, 15% 90%, 10% 95%, 
    5% 90%, 0% 95%, 0% 5%
  );
  
  clip-path: polygon(
    0% 5%, 5% 0%, 10% 5%, 15% 0%, 
    20% 5%, 25% 0%, 30% 5%, 35% 0%, 
    40% 5%, 45% 0%, 50% 5%, 55% 0%, 
    60% 5%, 65% 0%, 70% 5%, 75% 0%, 
    80% 5%, 85% 0%, 90% 5%, 95% 0%, 
    100% 5%, 100% 95%, 95% 90%, 90% 95%, 
    85% 90%, 80% 95%, 75% 90%, 70% 95%, 
    65% 90%, 60% 95%, 55% 90%, 50% 95%, 
    45% 90%, 40% 95%, 35% 90%, 30% 95%, 
    25% 90%, 20% 95%, 15% 90%, 10% 95%, 
    5% 90%, 0% 95%, 0% 5%
  );
}

.inner-bg {
  background: linear-gradient(45deg, #eae9cc, #b3d9ef);
  padding: 10px; 
  border-radius: 5px; 
  display: inline-block;
  animation: blink-inner 2s infinite alternate; 
}

.image-container {
  position: relative; 
  z-index: 10; 
}
.image-container img {
  position: relative; 
  z-index: 10 ;  
  opacity: 10 ;
}


/* Outer background blinking */
@keyframes blink-outer {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}


.typewriter-images {
  display: flex;
  overflow: hidden; 
}

.typewriter-images span {
  opacity: 0;
  animation: typewriter 0.5s linear forwards;
  margin-left: -25px;
}

.typewriter-images span:first-child {
  margin-left: 0; 
}

.typewriter-images span img {
  display: block; 
  max-width: 100%; 
  height: auto; 
}

.typewriter-images span:nth-child(1) {
  animation-delay: 0s;
}
.typewriter-images span:nth-child(2) {
  animation-delay: 0.5s;
}
.typewriter-images span:nth-child(3) {
  animation-delay: 1s;
}
.typewriter-images span:nth-child(4) {
  animation-delay: 1.5s;
}
.typewriter-images span:nth-child(5) {
  animation-delay: 2s;
}
.typewriter-images span:nth-child(6) {
  animation-delay: 2.5s;
}
.typewriter-images span:nth-child(7) {
  animation-delay: 3s;
}

@keyframes typewriter {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.btnposition{
  position: absolute;
  left:500px;
  top:480px
}

.bgforweddiv{
background-color: #288ED7;
}

.btngetfree{
background-color: #288ED7;
color: white;
}

@media  (min-width:2240px) and (max-width:2250px) {
  .bannerbackground{
  background-image: url("../images/bannersectionbg.png");
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 800px;

 }
.btnposition {
    position: absolute;
    left: 600px;
    top: 445px;
}
.card-imgtop{
  height: 300px;
}


  .heightforimac{
    height: 380px !important;
  }
  /* .tech-card {
    position: relative !important;
    height: 350px !important;
} */
 .swiperforimac{
  margin-top: 5rem !important;
 }
 .fontforimacbannerhead{
  font-size: 60px !important;
  line-height: 70px !important;

 }
 .btnposition{
  position: absolute;
        left: 770px;
        top: 561px;
 }
  .banner-box1 {
    right: 1%;
    left: 4%;
    top: 12%;
  }
 }


/* .backgroundforcontact{
  background-color: #1F2937 !important;
  color: white !important;
} */

.sentmsgbtn{
   background-color: #288ED7 !important;
}
.sentmsgbtn:hover{
  color:white !important;
  background-color: #15639b  !important;
}

.bgformodalcontact{
  background-color: transparent !important;
  color: white !important;
}