/* Outfit  */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
    --primary-color : rgba(15, 164, 210, 1);
    --secondary-color : rgba(44, 44, 44, 1);
    --basic-color : #2293BB;
    --secondary-light : #808690;
    --primary-background : #B9E6F4;

}
*{
    font-family: "Work Sans", serif;
}
 
.active{
    color: var(--primary-color) !important;
}
.primary-txt, .primary-color{
    color: var(--primary-color) !important;
}
.basic-color, .primary-light{
    color: var(--basic-color);
}
.primary-bg, .primary-back{
    background-color: var(--primary-background);
}
.secondary-txt, .secondary-color{
    color: var(--secondary-color) !important;
}
.secondary-light-color, .secondary-light-txt{
    color: var(--secondary-light);
}
button {
    border: none;
    background: transparent;
}
.btn.btn-primary {
    background: transparent;
    color: var(--basic-color);
    box-shadow: 0 2.2px 6px rgba(0,0,0,.2);
    border-color: var(--basic-color);
}
.btn.btn.btn-primary:hover {
    background-color: #1ea4d4;
    border-color: #1ea4d4;
}
.btn.btn-secondary {
    background-color: transparent;
    box-shadow: 0 2.2px 6px rgba(0,0,0,.2);
    color: black;
    border-color: #7e7e7e;
}
.light-heading{
    color: #CDCDCD;
}
.bg-light-sky{
    background-color: #F8FDFF;
}
.custom-card-style{
    background-color: white;
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
    border-radius: 5px;
}
.box-shodow{
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
}

/* Whatsapp connect */
#whatsapp .wtsapp{
    position: fixed;
    transform: all .5s ease;
    -webkit-transform: all .5s ease;
    -moz-transform: all .5s ease;
    -ms-transform: all .5s ease;
    -o-transform: all .5s ease;
    /* background-color: #25d366; */
    display: block;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) ;
    border-radius: 50px ;
    -webkit-border-radius: 50px ;
    -moz-border-radius: 50px ;
    -ms-border-radius: 50px ;
    -o-border-radius: 50px ;
    border-right: none;
    bottom: 30px;
    right: 25px;
    font-weight: 700;
    font-size: 20px;
    border: 0;
    z-index: 9999;
    width: 50px;
    height: 50px;
    line-height: 48px;

} 
  #whatsapp .wtsapp:before{
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background-color: #485b4fd6;
    border-radius: 50%;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
   
}

#whatsapp .wtsapp:focus{
    border: none;
    outline: none;
} 

@keyframes pulse-border {
    0%{ transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1);opacity: 1; ; }
    100%{ transform:translateX(-50%) translateY(-50%) translateZ(0) scale(1.5) ;opacity: 0; }
}
/* Bounce whatsapp logo */
/* .bounce2 {
    animation: bounce2 2s ease infinite;
} */

/* get in touch .whatsapp-connect */
.whats{
    box-shadow: 3px 6.2px 8px rgba(0,0,0,.2);
}




.shadoww{
    box-shadow: 0 1.2px 8px rgba(0,0,0,.1);
}


/* mediquery */
@media screen and ( min-width : 700px ) and ( max-width : 800px ) {
    br{
        display: none;
    }
}


