.thank-you-bg{
    background-image: url(../images/homeImages/thankyou-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    /* width: 100%; */
    /* height: 720px; */
}

 .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
    }
    .divfortext{
        position: absolute !important;
        top: 15% !important;
        left:5% !important;

    }
/* 
@media (min-width: 1600px) and  (max-width:2000px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
       
    }
    
} */

/* @media (min-width: 1400px) and  (max-width:1600px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;

        
    }
    
} */
/* @media (min-width: 1200px) and  (max-width:1400px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
       
    }
     .divfortext{
        position: absolute !important;
        top: 100px !important;

    }
    
} */


/* @media (min-width: 1000px) and  (max-width:1200px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
       
    }
    
} */



 @media (min-width:1250px) and (max-width:1300px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
    }
    .divfortext{
        position: absolute !important;
        top: 150px !important;
        left: 90px !important;

    }
 }

 @media (min-width:1350px) and (max-width:1370px) {
     .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
        overflow: hidden;
    }
    .divfortext{
        position: absolute !important;
        top: 20% !important;
        left:10% !important;

    }
 }

@media (min-width:1550px) and (max-width:1600px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
        overflow: hidden;
    }
    .divfortext{
        position: absolute !important;
        top: 20% !important;
        left:10% !important;

    }
}
@media (min-width:1650px) and (max-width:1700px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
        overflow: hidden;
    }
    .divfortext{
        position: absolute !important;
        top: 20% !important;
        left:10% !important;

    }
}
@media (min-width:1900px) and (max-width:1930px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
        overflow: hidden;
    }
    .divfortext{
        position: absolute !important;
        top: 20% !important;
        left:15% !important;

    }
}

@media (min-width:1380px) and (max-width:1400px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
        overflow: hidden;
    }
    .divfortext{
        position: absolute !important;
        top: 20% !important;
        left:10% !important;

    }
}

@media (min-width:2200px) and (max-width:2500px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 100vh;
        overflow: hidden;
    }
    .divfortext{
        position: absolute !important;
        top: 20% !important;
        left:19% !important;

    }
}


@media screen and (max-width:480px){
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 842px;
    }
    
}

@media  (min-width:420px) and   (max-width:460px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 930px;
    }
    .divfortext{
        position: absolute !important;
        top: 100px !important;

    }

    
}

@media  (min-width:412px) and   (max-width:420px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 895px;
    }
     .divfortext{
        position: absolute !important;
        top: 100px !important;

    }
    
}


@media  (min-width:400px) and   (max-width:412px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 915px;
    }
     .divfortext{
        position: absolute !important;
        top: 100px !important;

    }
    
}@media  (min-width:370px) and   (max-width:400px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        /* height: 840px; */
    }
    .divfortext{
        position: absolute !important;
        top: 57px !important;

    }
    
}



@media  (min-width:330px) and   (max-width:370px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 740px;
    }
     .divfortext{
        position: absolute !important;
        top: 57px !important;

    }
    
}

@media  (min-width:370px) and   (max-width:380px) {
    .thank-you-bg{
        background-image: url(../images/homeImages/thankyou-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        /* width: 100%; */
        height: 666px;
    }
    
}