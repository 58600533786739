.footer-bg {
  background-image: url("../images/footer.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.foot-pad {
  padding: 1rem 0 0.9rem;
}
.twitter {
  width: 18%;
}
.social .insta svg,
.social .linkedin svg {
  width: 2rem;
  height: 2rem;
}
.social .facebook svg {
  width: 1.5rem;
  height: 1.5rem;
}
.social div {
  padding: 1rem 1rem;
}
.footer-bg .small-size {
  font-size: 13px;
}
.textcolor{
  color: #B1B1B1;
}
.textcolorfooter{
  color:#817F7F;
}
.textcolorcont{
  color: #B1B1B1;
}

/* Mediaquery */

@media screen and (max-width: 1100px) {
  .social div {
    padding: 0.8rem 0.4rem;
  }
  .twitter {
    width: 21%;
  }
  .social-section {
    padding-left: 2rem;
  }
  
}

@media screen and (min-width: 700px) and (max-width: 800px) {
  .twitter {
    width: 8%;
  }
  .social-section {
    padding-left: 0rem;
  }
  #logo1 {
    width: 45px;
    height: 45px;
  }
  .img {
    height: 100px;
    width: 80px;
  }
}

@media screen and (max-width: 654px) and (min-width: 511px) {
  .twitter {
    width: 12%;
  }
  #logo1 {
    height: 40px;
    width: 40px;
  }
  .img {
    height: 70px;
    width: 100px;
  }
  .twitter {
    width: 10%;
  }
}

@media screen and (max-width: 480px) {
  .logo-txt {
    text-align: center;
    padding-bottom: 1rem;
    
  }
  .social-section {
    padding-left: 0rem;
    padding-top: 1rem;
  }
  .twitter img.img-fluid {
    width: 50% !important;
  }
  .linkedin svg {
    width: 1.9rem !important;
    height: 1.9rem !important;
  }
  .footer-bg {
    background-position: initial;
  }
  #logo1 {
    width: 55px;
    height: 55px;
  }
  /* .img {
    width: 130px;
  } */
  .social div {
    padding: 20px;
  }
}

@media screen and (max-width: 300px) {
  .twitter img.img-fluid {
    width: 70% !important;
  }
  .menulinks {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  #logo1 {
    height: 40px;
    width: 40px;
  }
  .img {
    height: 70px;
    width: 100px;
  }
  .menulinks {
    font-size: 10px;
  }

  .twitter {
    width: 20%;
  }
}
@media (min-width: 480px) and (max-width: 511px) {
  #logo1 {
    height: 40px;
    width: 40px;
  }
  .img {
    height: 70px;
    width: 100px;
  }
  .twitter {
    width: 15%;
  }
}

.forborder:hover{
  border-bottom: 1px solid;
}