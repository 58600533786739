.contact-btn {
    background-color: var(--primary-color) !important;
    color: white !important;
}

.contact-btn:hover {
    background-color: #2293BB !important;
    color: black !important;
}

#contactForm {
    height: 460px;
}



/* Input number text arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.phoneinput:focus{
    background-color: black;
    outline: none;
    border:none;
}


.phoneinput {
  background-color: black !important;
}



/* Mediaquery */
@media screen and (min-width : 767px) and (max-width : 1050px) {
    .testCaptcha {
        transform: scale(0.80);
        transform-origin: 0 0;
    }
}

@media screen and (max-width : 480px) {
    .captcha .mob-wid {
        width: 100% !important;
    }
}

@media screen and (max-width : 350px) {

    .captcha>div>div,
    .captcha iframe {
        width: 100% !important;
    }

    .contact-btn {
        width: 100%;
    }

}

.custom-phone-input {
  
  background-color: transparent !important;
  color: white !important;
  border-radius: 5px; 
  padding: 4px; 
}

.custom-phone-input input {
  
      background-color: transparent!important;
      color: white !important;

}
.PhoneInput .PhoneInputInput:focus-visible {
    outline: none !important;
    border: none !important;
    
}
.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
    color: white;
    background-color:  transparent !important;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: white !important;
    appearance: none;
    /* background-color:  #131C29 !important; */
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.PhoneInputInput {
    border:none !important;
    outline:none
}
.forinputfocus :focus{
    outline: none !important;
    box-shadow: none !important;
    /* background-color: black !important; */  
  color: white !important;
   
}

.bginputform{
    background-color: transparent !important;
    color: white !important;
}

/* input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #1f2937 inset !important;
    box-shadow: 0 0 0px 1000px #1f2937 inset !important;
    -webkit-text-fill-color: #ffffff !important;
    -webkit-background-clip: #e22424 !important;
} */


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important; 
    box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-text-fill-color: white !important; 
    transition: background-color 5000s ease-in-out 0s; 
}



/* input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(15, 14, 14) inset !important; 
    box-shadow: 0 0 0px 1000px rgb(15, 14, 14) inset !important;
    -webkit-text-fill-color: black !important; 
} */

/* .input:internal-autofill-selected:focus{
     
background-color: #131C29 !important;

}
 */


