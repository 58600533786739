.stats-color{
    color: #0FA4D2;
}
@media screen and (max-width : 480px) {
    /* .solution-card .img img {
        width: 40%;
    } */
    .Stats-container .container .statsdiv .vr{
        margin-right: 2px;
        
    }
}

@media (min-width: 2520px) and (max-width: 4480px) {
    /* Add your CSS styles for iMac resolution here */
   /* .Stats-container{
      max-width:200% !important;
   } */
  }
  /* .bgstat{
  } */
.statbgimg{
    background-image: url("../../images/ststbackground.png");
    background-size: cover;
       background-repeat: no-repeat;
        
}